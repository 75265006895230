import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Content, Header, Tabbar, Input, Card, Button, Icon, Checkbox, Modal, Alert } from '@citadeldao/apps-ui-kit/dist/main';
import AddressBlock from '@citadeldao/apps-ui-kit/dist/components/uiKit/AddressBlock'
import { Config } from '../config/config';
import { swapActions, walletActions } from '../../store/actions';
import { sortList } from '../helpers';
import { prettyNumber } from '../helpers/numberFormatter';
import { searchToken } from '../helpers/searchToken';
import { formatAddress } from '../helpers/addressFormatter';
import ROUTES from '../../routes';
import '../styles/panels/selectTokenPanel.css';

const SelectTokenPanel = () => {
  const config = new Config()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { bottomInset } = useSelector(state => state.panels)
  const { tokens, coingeckoTokens, extendedTokens, coinMarketCapTokens } = useSelector((state) => state.wallet)
  const { tokenIn, tokenOut, selectedToken } = useSelector((state) => state.swap)
  const previousPanel = useSelector(state => state.panels.previousPanel)
  const activeToken = selectedToken === 'INPUT' ? tokenIn : tokenOut
  const secondToken = selectedToken !== 'INPUT' ? tokenIn : tokenOut
  let listSorted = tokens?.filter(elem => elem?.symbol !== secondToken?.symbol)
  const [tokenList, setTokenList] = useState(sortList(listSorted))
  const [customTokens, setCustomTokens] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [token, setTokens] = useState({})
  const [customTokenList, setCustomTokenList] = useState(customTokens)

  const back = () => navigate(previousPanel + '?' + window.location.search.slice(1))

  const searchWallet = (wallet) => {
    if(wallet.length > 0){
      if(searchToken(listSorted, wallet, secondToken)?.length === 0){
        setTokenList([])
        searchCustomWallet(wallet)
      } else {
        setTokenList(searchToken(listSorted, wallet, secondToken))
        searchCustomWallet(wallet)      
      }
    }else{
      setTokenList(sortList(listSorted));
      setCustomTokens([])
      setCustomTokenList([])
    }
  };

  const searchCustomWallet = (wallet) => {
    let findedTokens = searchToken(coingeckoTokens, wallet, secondToken, listSorted)
    if(!findedTokens.length){
      findedTokens = searchToken(extendedTokens, wallet, secondToken, listSorted)
      if(!findedTokens.length){
        findedTokens = searchToken(coinMarketCapTokens, wallet, secondToken, listSorted)
      }
    }
    setCustomTokens(findedTokens)
    setCustomTokenList(findedTokens.filter(ar => !tokenList.find(rm => (rm.address === ar.address))))
  }
     
  const setToken = (token) => {
    dispatch(selectedToken === 'INPUT' ? swapActions.setTokenIn(token) : swapActions.setTokenOut(token));
    back();
  }

  const importToken = () => {
    walletActions.checkTokens(token)
    dispatch(selectedToken === 'INPUT' ? swapActions.setTokenIn(token) : swapActions.setTokenOut(token));
    navigate(ROUTES.SWAP)
  }

  return (
    <div className='panel'>
      <Content>
        <Header border title="Select token" style={{margin: '8px 0 16px 0'}} onClick={() => back()} back={true}/>
        <Input type="search" style={{marginBottom: '10px'}} onChange={searchWallet} placeholder='Start typing..'/>
        <div className='manage-block' >
          <div onClick={() => navigate(ROUTES.MANAGE_TOKEN)} style={{cursor: 'pointer'}}>Manage tokens</div> 
        </div>
        {tokenList?.map((elem,i) =>(
          <AddressBlock 
            logoURI={elem.logoURI} 
            onClick={() => setToken(elem)} 
            active={activeToken?.symbol === elem?.symbol} 
            style={{marginBottom: '10px'}} 
            data={{...elem, code: elem?.symbol, balance: prettyNumber(elem?.balance)}} 
            key={i}
        />  
        ))}
        {!customTokenList.length && !tokenList.length ? <p className='no-data'>No data found</p> : ""}
        {customTokenList && customTokenList?.map((token, i) => (
          <Card className="token__card-block" key={i}>
            <div className='token__card'>
              <img src={token?.logoURI} alt='token-img'></img>
              <p>{token?.name} <span className='token__source'> via {token?.source}</span></p>
            </div>
            {listSorted?.some(element => (
                element?.address === token?.address )) ?
                    <Button 
                        type='small' 
                        style={{cursor: "default"}}
                        disabled
                    >
                        IMPORTED
                    </Button>
                :  
                    <Button 
                    type='small' 
                    hoverTextColor='white' 
                    hoverBgColor='#5639E0'
                    onClick={() =>  {
                      setShowModal(true)
                      setTokens(token)
                    }}
                >
                    IMPORT
                </Button>
            
            } 
          </Card>
        ))}
        <Modal
          title={<Alert text='Trade at your own risk!' iconColor="#ED4242" boldText/>}
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <Card className="token__card-block">
            <div className='token__card'>
              <img src={token?.logoURI} alt='token-img'></img>
              <div className='token__info'>
                <p className='token__name'>{token?.name} / <span>{token?.symbol}</span></p>
                <p className='token__address'>{formatAddress(token?.address)}</p>
              </div>
            </div>
            <a className='token__link' href={`https://bscscan.com/address/${token?.address}`} rel="noreferrer" target={'_blank'}>
                <Icon name = "arrow-from-square-up-right" color="#00B2FE"/>
            </a>
          </Card>
          <p className='token__desc'>Anyone can create a token, including creating fake versions of existing tokens that claim to represent projects. If you purchase this token, you may not be able to sell it back</p>
          <div className='div--center'>
            <Checkbox
              value={isSelected}
              onChange={(val) => setIsSelected(val)}
            >
              I understand
            </Checkbox>
            <Button
              disabled={!isSelected && true}
              bgColor={isSelected && '#7C63F5'}
              className='btn'
              onClick={() => importToken()}
            >
              IMPORT
            </Button>
          </div>
        </Modal>
      </Content>
      <Tabbar config={config}  bottomInset={bottomInset}/>
    </div>
  )
}

export default SelectTokenPanel