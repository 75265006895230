export const types = {
    SET_OPENED_TRANSACTION: "SET_OPENED_TRANSACTION",
    SET_TRANSACTIONS_LIST: "SET_TRANSACTIONS_LIST",
    SET_TRANSACTIONS_CLEAR: "SET_TRANSACTIONS_CLEAR",
    SET_TRANSACTIONS_LOADED: "SET_TRANSACTIONS_LOADED",
    SET_OPENED_ADDRESS: "SET_OPENED_ADDRESS",
    SET_ADDRESS_LIST: "SET_ADDRESS_LIST",
    SET_CURRENT_WALLET: "SET_CURRENT_WALLET",
    SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
    SET_ERRORS: "GET_ERRORS",
    SET_NETWORK_ERRORS: "SET_NETWORK_ERRORS",
    SET_ARGUMENTS_ERRORS: "SET_ARGUMENTS_ERRORS",
    SET_VALIDATION_ERRORS: "SET_VALIDATION_ERRORS",
    SET_IMPLEMENTATION_ERRORS: "SET_IMPLEMENTATION_ERRORS",
    SET_WALLETS: "SET_WALLETS",
    SET_LOADER: "SET_LOADER",
    SET_NETWORKS: "SET_NETWORKS",
    SET_STAKE_LIST: "SET_STAKE_LIST",
    SET_BALANCE_INFO: "SET_BALANCE_INFO",
    SET_STAKE_NODES: "SET_STAKE_NODES",
    SET_ERROR_MODAL: "SET_ERROR_MODAL",
    SET_SOCKET_TOKEN: "SET_SOCKET_TOKEN",
    SET_PREPARE_TRANSFER_RESPONSE: "SET_PREPARE_TRANSFER_RESPONSE",
    SET_USER_CONFIG: "SET_USER_CONFIG",
    SET_ACTIVE_WALLET: "SET_ACTIVE_WALLET",
    SET_SPLASH_MODE: "SET_SPLASH_MODE",
    SET_PREVIOUS_PANEL: "SET_PREVIOUS_PANEL",
    SET_SWAP_STATUS: "SET_SWAP_STATUS",
    SET_DISABLE_SWAP: "SET_DISABLE_SWAP",
    SET_SWAP_FEE: "SET_SWAP_FEE",
    SET_FIELD: "SET_FIELD",
    SET_POOL_INFO: "SET_POOL_INFO",
    SET_TOKEN_IN: "SET_TOKEN_IN",
    SET_TOKEN_OUT: "SET_TOKEN_OUT",
    SET_SWAP_RATE: "SET_SWAP_RATE",
    SET_SLIPPAGE: "SET_SLIPPAGE",
    SET_POOL_ID: "SET_POOL_ID",
    SET_INITIAL_RATE: "SET_INITIAL_RATE",
    SET_RATE_AMOUT: "SET_RATE_AMOUT",
    SET_SLIPPAGE_TOLERANCE: "SET_SLIPPAGE_TOLERANCE",
    SET_OUT_AMOUNT: "SET_OUT_AMOUNT",
    SET_FROM_USD_PRICE: "SET_FROM_USD_PRICE",
    SET_TO_USD_PRICE: "SET_TO_USD_PRICE",
    SET_POOLS_LIST: "SET_POOLS_LIST",
    SET_SWAP_INFO: " SET_SWAP_INFO",
    SET_AMOUNT: "SET_AMOUNT",
    SET_TOKENS: "SET_TOKENS",
    SET_CONFIRM_MODAL: "SET_CONFIRM_MODAL",
    SET_SELECTED_TOKEN: "SET_SELECTED_TOKEN",
    UPDATE_VERSION: 'UPDATE_VERSION',
    ACCEPT_LIST_UPDATE: 'ACCEPT_LIST_UPDATE',
    ADD_LIST: 'ADD_LIST',
    REMOVE_LIST: 'REMOVE_LIST',
    FETCH_TOKEN_LIST: 'FETCH_TOKEN_LIST',
    ENABLE_LIST: 'ENABLE_LIST',
    DISABLE_LIST: 'DISABLE_LIST',
    ADD_MULTICAL_LISTENERS: "ADD_MULTICAL_LISTENERS",
    REMOVE_MULTICAL_LISTENERS: "REMOVE_MULTICAL_LISTENERS",
    FETCHING_MULTICAL_RESULTS: "FETCHING_MULTICAL_RESULTS",
    UPDATE_MULTICAL_RESULTS: "UPDATE_MULTICAL_RESULTS",
    ERROR_FETCHINT_MULTICAL_RESULTS: "ERROR_FETCHINT_MULTICAL_RESULTS",
    SET_CALLS: "SET_CALLS",
    SET_ALLOWANCE: "SET_ALLOWANCE",
    SET_DEADLINE_MINUTE: "SET_DEADLINE_MINUTE",
    SET_ALLOWED_PAIRS: "SET_ALLOWED_PAIRS",
    INVALID_RESULT: "INVALID_RESULT",
    SET_BLOCK: "SET_BLOCK",
    SET_TRADE: "SET_TRADE",
    SET_MIN_RECEIVED: "SET_MIN_RECEIVED",
    SET_PARSED_AMOUNT: "SET_PARSED_AMOUNT",
    SET_EXACT_IN: "SET_EXACT_IN",
    SET_UPDATED_TRADE: "SET_UPDATED_TRADE",
    SET_IN_AMOUNT: "SET_IN_AMOUNT",
    SET_USD_PRICE: "SET_USD_PRICE",
    SET_COINGECKO_TOKENS: "SET_COINGECKO_TOKENS",
    SET_COINMARKETCAP_TOKENS: "SET_COINMARKETCAP_TOKENS",
    SET_EXTENDED_TOKENS: "SET_EXTENDED_TOKENS",
    SET_CUSTOM_TOKENS: "SET_CUSTOM_TOKENS",
    SET_CUSTOM_TOKEN: "SET_CUSTOM_TOKEN",
    SET_CUSTOM_TOKEN_CONFIG: "SET_CUSTOM_TOKEN_CONFIG",
    SET_CURRENT_PANEL: "SET_CURRENT_PANEL",
    SET_TRANSACTIONS_PAGE: "SET_TRANSACTIONS_PAGE"
};