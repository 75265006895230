import { Modal, Icon, InfoCardItem, SwapBalanceCard, Button, InfoCardBlock, PriceUpdatedCard, } from '@citadeldao/apps-ui-kit/dist/main'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { errorActions } from '../../store/actions';
import { ONE_BIPS } from '../../networking/constants/constants';
import { swapActions } from '../../store/actions';
import { prettyNumber } from '../helpers/numberFormatter';

const ConfirmModal = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const { amount, tokenIn, tokenOut, trade, minReceived } = useSelector(state => state.swap)
    const showConfirmModal = useSelector(state => state.errors.openConfirmModal)
    const [disabledSwap, setDisabledSwap] = useState(true)
    const formattedPrice = trade?.executionPrice?.toSignificant(6)
    
    let routes = [];

    if(tokenIn?.symbol === 'BNB' && tokenOut?.symbol === 'WBNB'){
        routes.push({logoURI: tokenIn?.logoURI, name: tokenIn?.symbol})
        routes.push({logoURI: tokenOut?.logoURI, name: tokenOut?.symbol})
    }else if(tokenIn?.symbol === 'WBNB' && tokenOut?.symbol === 'BNB'){
        routes.push({logoURI: tokenIn?.logoURI, name: tokenIn?.symbol})
        routes.push({logoURI: tokenOut?.logoURI, name: tokenOut?.symbol})
    }else if(trade?.route?.path){
        routes = trade?.route?.path?.map(item => {
            return {
                logoURI: item.tokenInfo?.logoURI,
                name: item.symbol,
            };
        });
    }
return(
  <Modal show={showConfirmModal && !location.pathname.includes('/info')} title="Confirm swap" onClose={() => dispatch(errorActions.setConfirmModal(false))}>
    <div>
      <br/>
      <div className='row'>
          <SwapBalanceCard width='45%' icon='bsc' amount={prettyNumber(amount)} bgColor='#B7F6FF' color='#00BFDB' token={{...tokenIn, code: tokenIn.symbol}} />
          <Icon name='triangle-right' width='16px'/>
          <SwapBalanceCard width='45%'  icon='bsc' amount={trade?.outputAmount.toSignificant(6)} bgColor='#C6D1FF' color='rgba(58, 94, 229, 1)' token={{...tokenOut, code: tokenOut.symbol}} />
      </div>
      { disabledSwap && <PriceUpdatedCard style={{margin: '16px 0'}} acceptPrice={() => setDisabledSwap(false)} text='Price updated'/>}
      <InfoCardBlock style={{marginTop: '10px'}}>
          <InfoCardItem text={'Price'} symbol={tokenOut?.symbol} symbol2={tokenIn?.symbol}><span className='purple-text'>{formattedPrice || '-'}</span></InfoCardItem>
          <InfoCardItem text={'Price impact'} symbol={'%'}><span className='green-text'>{trade?.priceImpactWithoutFee ? (trade?.priceImpactWithoutFee.lessThan(ONE_BIPS) ? '<0.01' : `${trade?.priceImpactWithoutFee.toFixed(2)}`) : '-'}</span></InfoCardItem>
          <InfoCardItem text={'Minimum received'} symbol={tokenOut?.symbol}><span className='purple-text'>{minReceived !== 0 ? minReceived?.toSignificant(4) : minReceived}</span></InfoCardItem>
          <InfoCardItem text={'Liquidity Provider Fee'} symbol={tokenIn?.symbol}><span className='pink-text'>{trade?.realizedLPFee?.toSignificant(4) || 0}</span></InfoCardItem>
          {
              routes?.length ?
              <InfoCardItem text={'Route'} routes={routes}/>:
              <InfoCardItem text={'Route'} symbol={'-'}/>
          }
      </InfoCardBlock>
      <div className='center'>
        <Button 
          disabled={disabledSwap} 
          onClick={() => { dispatch(errorActions.setConfirmModal(false)); dispatch(swapActions.getSwapTransaction())}} 
          hoverBgColor='#5639E0' 
          style={{marginTop: '20px'}} 
          textColor='#FFFFFF' 
          bgColor='#7C63F5'>
        SWAP</Button>
      </div>
    </div> 
  </Modal>
)}

export default ConfirmModal