import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { StatusPopup, PopupWindow, TipCard, Panel, Modal, View, AddressSectionCard, Alert }  from '@citadeldao/apps-ui-kit/dist/main';
import SwapPanel from './components/panels/SwapPanel';
import TransactionsPanel from './components/panels/TransactionsPanel';
import SelectTokenPanel from './components/panels/SelectTokenPanel';
import TransactionsDetailsPanel from './components/panels/TransactionDetails';
import InfoPanel from './components/panels/InfoPanel'
import SelectAddressPanel from './components/panels/SelectAddressPanel';
import ManageTokensPanel from './components/panels/ManageTokensPanel';
import GuidesPanel from './components/panels/GuidesPanel';

import { errorActions, swapActions } from './store/actions'
import { prettyNumber } from './components/helpers/numberFormatter';
import { Config } from './components/config/config';
import ROUTES from "./routes";
import text from './text.json';
import './components/styles/panels/index.css';

const MainView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const config = new Config();

  const showModal = useSelector(state => state.errors.openErrorModal)
  const { errors } = useSelector(state => state.errors)
  const { activeWallet, allowance } = useSelector(state => state.wallet)
  const { amount, tokenIn } = useSelector(state => state.swap)
  const { borderRadius } = useSelector(state => state.panels)
  const [showSuccess, setShowSuccess] = useState(errors)

  useEffect(() => {
    setShowSuccess(errors)
    dispatch(swapActions.checkSwapStatus(amount))
    // eslint-disable-next-line 
  }, [errors,allowance,tokenIn]);

  const clearErrors = () => {
    setShowSuccess(false);
    dispatch(errorActions.clearErrors());
  };

  let wallet = activeWallet;
  if (activeWallet) {
    wallet = {...activeWallet,balance: prettyNumber(activeWallet?.balance)}
  }

  return(
    <View>
      <Panel config={config} style={{borderRadius: `${borderRadius}px`}}>
        <AddressSectionCard onClick={() => navigate(ROUTES.SELECT_ADDRESS)} className='select-address-card' data={wallet} id='/show'></AddressSectionCard>
        <PopupWindow show={showSuccess} id='/show'>
          <StatusPopup text={errors?.text} type='error' showPopup={clearErrors}/>       
        </PopupWindow>
        <SwapPanel id={ROUTES.SWAP} />
        <TransactionsPanel id={ROUTES.TRANSACTIONS} />
        <GuidesPanel id={ROUTES.INFO_MENU_GUIDE} />
        <SelectAddressPanel id={ROUTES.SELECT_ADDRESS} />
        <SelectTokenPanel id={ROUTES.SELECT_TOKEN} />
        <ManageTokensPanel id={ROUTES.MANAGE_TOKEN} />
        <TransactionsDetailsPanel id={ROUTES.TRANSACTION_DETAILS} />
        <Modal
          title={
            <Alert
              title='Address required for this app'
              iconColor='#00B2FE'
              textColor='#026573'
            />
          }
          description= {text.ADDRESS_ERROR_DESCRIPTION}
          show={showModal}
          id={ROUTES.SWAP}
          borderRadius={borderRadius}
          canClose={false}
          className='address__modal'
        >


          <TipCard text={text.ADDRESS_ERROR_TIP}/>
        </Modal>
      </Panel>
      <InfoPanel config={config}/>
  </View>
  );
};

export default MainView;