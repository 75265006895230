import { CurrencyAmount, Price, Fraction, JSBI, Percent, Currency, Trade, TradeType } from '@pancakeswap/sdk'
import { TradeWithStableSwap, RouteType, Trade as SmartTrade, isStableSwapPair } from '@pancakeswap/smart-router/evm'
import { TradeType as TradeType2 } from "@pancakeswap/swap-sdk-core";
import invariant6 from "tiny-invariant";
import invariant from "tiny-invariant";
import { getAddress } from "@ethersproject/address";
import warning from "tiny-warning";

const ZERO_HEX = "0x0";
const BASE_FEE = new Percent(JSBI.BigInt(25), JSBI.BigInt(10000))
const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000))
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

export function validateAndParseAddress(address) {
    try {
      const checksummedAddress = getAddress(address);
      warning(address === checksummedAddress, `${address} is not checksummed.`);
      return checksummedAddress;
    } catch (error) {
      invariant(false, `${address} is not a valid address.`);
    }
  }

export function toHex(currencyAmount) {
  return `0x${currencyAmount.quotient.toString(16)}`;
}

export function swapCallParameters(trade, options) {
  const etherIn = trade.inputAmount.currency.isNative;
  const etherOut = trade.outputAmount.currency.isNative;
  invariant6(!(etherIn && etherOut), "ETHER_IN_OUT");
  invariant6(!("ttl" in options) || options.ttl > 0, "TTL");
  const to = validateAndParseAddress(options.recipient);
  const amountIn = toHex(SmartTrade.maximumAmountIn(trade,options.allowedSlippage));
  const amountOut = toHex(SmartTrade.minimumAmountOut(trade,options.allowedSlippage));
  const path = trade.route.path.map((token) => token.address);
  const deadline = "ttl" in options ? `0x${(Math.floor(new Date().getTime() / 1e3) + options.ttl).toString(16)}` : `0x${options.deadline.toString(16)}`;
  const useFeeOnTransfer = Boolean(options.feeOnTransfer);
  let methodName;
  let args;
  let value;
  switch (trade.tradeType) {
    case TradeType2.EXACT_INPUT:
      if (etherIn) {
        methodName = useFeeOnTransfer ? "swapExactETHForTokensSupportingFeeOnTransferTokens" : "swapExactETHForTokens";
        args = [amountOut, path, to, deadline];
        value = amountIn;
      } else if (etherOut) {
        methodName = useFeeOnTransfer ? "swapExactTokensForETHSupportingFeeOnTransferTokens" : "swapExactTokensForETH";
        args = [amountIn, amountOut, path, to, deadline];
        value = ZERO_HEX;
      } else {
        methodName = useFeeOnTransfer ? "swapExactTokensForTokensSupportingFeeOnTransferTokens" : "swapExactTokensForTokens";
        args = [amountIn, amountOut, path, to, deadline];
        value = ZERO_HEX;
      }
      break;
    case TradeType2.EXACT_OUTPUT:
      invariant6(!useFeeOnTransfer, "EXACT_OUT_FOT");
      if (etherIn) {
        methodName = "swapETHForExactTokens";
        args = [amountOut, path, to, deadline];
        value = amountIn;
      } else if (etherOut) {
        methodName = "swapTokensForExactETH";
        args = [amountOut, amountIn, path, to, deadline];
        value = ZERO_HEX;
      } else {
        methodName = "swapTokensForExactTokens";
        args = [amountOut, amountIn, path, to, deadline];
        value = ZERO_HEX;
      }
      break;
  }
  return {
    methodName,
    args,
    value
  };
}
export function computeTradePriceBreakdown(trade: Trade<Currency, Currency, TradeType> | null): {
  priceImpactWithoutFee: Percent | undefined
  realizedLPFee: CurrencyAmount<Currency> | undefined | null
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>(
          (currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      )
  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade?.priceImpact.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
    )

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}
  
export function computeTradePriceBreakdownSmartRouter(trade?: TradeWithStableSwap<Currency, Currency, TradeType> | null): {
  priceImpactWithoutFee: Percent | undefined
  realizedLPFee: CurrencyAmount<Currency> | undefined | null
} {
 
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>(
          (currentFee: Fraction, pair): Fraction =>
            currentFee.multiply(
              isStableSwapPair(pair) ? ONE_HUNDRED_PERCENT.subtract(pair.fee) : INPUT_FRACTION_AFTER_FEE,
            ),
          ONE_HUNDRED_PERCENT,
        ),
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction =
    trade && realizedLPFee ? SmartTrade.priceImpact(trade).subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
    )
  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}


export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export interface StableTrade {
  tradeType: TradeType
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  executionPrice: Price<Currency, Currency>
  priceImpact: null
  maximumAmountIn: (slippaged: Percent) => CurrencyAmount<Currency>
  minimumAmountOut: (slippaged: Percent) => CurrencyAmount<Currency>
}

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export function computeSlippageAdjustedAmounts(
  trade: Trade<Currency, Currency, TradeType> | StableTrade | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  }
}

export function computeSlippageAdjustedAmountsSmart(
  trade: TradeWithStableSwap<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade && SmartTrade.maximumAmountIn(trade, pct),
    [Field.OUTPUT]: trade && SmartTrade.minimumAmountOut(trade, pct),
  }
}

interface Options {
  trade?: TradeWithStableSwap<Currency, Currency, TradeType> | null
  v2Trade?: Trade<Currency, Currency, TradeType> | null
}

export const isSmartRouterBetter = ({ trade, v2Trade }: Options) => {
  if (!trade || !v2Trade) {
    return false
  }
  // trade might be outdated when currencies changed
  if (
    !trade.inputAmount.currency.equals(v2Trade.inputAmount.currency) ||
    !trade.outputAmount.currency.equals(v2Trade.outputAmount.currency) ||
    // Trade is cached so when changing the input, trade might be outdated
    (trade.tradeType === v2Trade.tradeType && !trade.inputAmount.equalTo(v2Trade.inputAmount)) ||
    // Trade should share the same path with v2 trade
    !isSamePath(trade.route.path, v2Trade.route.path)
  ) {
    return false
  }

  return trade.route.routeType !== RouteType.V2 && trade.outputAmount.greaterThan(v2Trade.outputAmount)
}

function isSamePath(one: Currency[], another: Currency[]) {
  if (one.length !== another.length) {
    return false
  }
  for (let i = 0; i < one.length; i += 1) {
    if (!one[i].equals(another[i])) {
      return false
    }
  }
  return true
}
