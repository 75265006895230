import { utils } from '@citadeldao/apps-sdk';

const getCoingeckoTokens = () => {
  return new utils.Request(
    'get',
    `https://tokens.pancakeswap.finance/coingecko.json`
  );
};

const getCoinMarketCapTokens = () => {
  return new utils.Request(
    'get',
    `https://tokens.pancakeswap.finance/cmc.json`
  )
}

const getExtendedTokens = () => {
  return new utils.Request (
    'get',
    `https://tokens.pancakeswap.finance/pancakeswap-extended.json`
  )
}

const defaultTokens = () => {
  return new utils.Request(
    'get',
    `https://tokens.pancakeswap.finance/pancakeswap-default.json`
  )
}

export const tokens = {
  getCoingeckoTokens,
  getCoinMarketCapTokens,
  getExtendedTokens,
  defaultTokens
}