import { store } from "../store";
import { types } from "./types";
import { errorActions, walletActions } from "./index";
import { getParsedAmount, getBestV2Trade } from '../../networking/methods/swap.js';
import { getCurrency } from '../../networking/methods/tokens.ts';
import { isSmartRouterBetter, computeTradePriceBreakdown, computeSlippageAdjustedAmountsSmart, computeSlippageAdjustedAmounts, computeTradePriceBreakdownSmartRouter } from '../../networking/utils/trade.ts';
import BigNumber from 'bignumber.js';
import { RouteType, Trade as SmartRouterTrade } from '@pancakeswap/smart-router/evm'
import { SMART_ROUTER_ADDRESS, V2_ROUTER_ADDRESS } from '../../networking/constants/constants'

const setSwapDisable = (status) => (dispatch) => {
  dispatch({
    type: types.SET_DISABLE_SWAP,
    payload: status,
  });
};
const setSwapStatus = (status) => (dispatch) => {
  dispatch({
    type: types.SET_SWAP_STATUS,
    payload: status,
  });
};
  
const setSlippageTolerance = (procent) => (dispatch) => {
  dispatch({
    type: types.SET_SLIPPAGE_TOLERANCE,
    payload: procent,
  });
  const { amount } = store.getState().swap
  dispatch(checkSwapStatus(amount))
};

const setIndependentField = (field) => (dispatch) => {
  dispatch({
    type: types.SET_FIELD,
    payload: field,
  });
};

const setAmount = (amount,isExactIn=true) => (dispatch) => {
  dispatch({
    type: types.SET_AMOUNT,
    payload: amount,
  });
  dispatch({
    type: types.SET_EXACT_IN,
    payload: isExactIn,
  });
};

const setTokenIn = (token, tradeUpdate = true) => async(dispatch) => {
  if(tradeUpdate){
    dispatch(setTrade(null))
    dispatch(setAmountOut(0))
  }
  const wallet = walletActions.getWalletConstructor()
  if(!token.balance && token.symbol !== "BNB"){
    let balance = await wallet.getTokenBalance(token)
    if(balance){
      token.balance = walletActions.formatBalance(balance?._hex,+token.decimals)
    }
  }
  dispatch({
    type: types.SET_TOKEN_IN,
    payload: token,
  });
  if(token?.symbol !== 'BNB'){
    const { activeWallet } = store.getState().wallet;
    const wallet = walletActions.getWalletConstructor(activeWallet);
    let allowance = await wallet.loadTokenAllowance(token)
    store.dispatch({
      type: types.SET_ALLOWANCE,
      payload: allowance,
    });
    const { amount } = store.getState().swap
    dispatch(checkSwapStatus(amount))
  }
};

const setTokenOut = (token,tradeUpdate = true) => async(dispatch) => {
  if(tradeUpdate){
    dispatch(setTrade(null))
    dispatch(setAmountOut(0))
  }
  const wallet = walletActions.getWalletConstructor()
  if(!token.balance && token.symbol !== "BNB"){
    let balance = await wallet.getTokenBalance(token)
    if(balance){
      token.balance = walletActions.formatBalance(balance?._hex,+token.decimals)
    }
  }
  dispatch({
    type: types.SET_TOKEN_OUT,
    payload: token,
  });
};

const setSelectedToken = (token) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_TOKEN,
    payload: token,
  });
};

const setAmountOut = (amount) => dispatch => {
  dispatch({
    type: types.SET_OUT_AMOUNT,
    payload: amount
  });
}

const setAmountIn = (amount) => dispatch => {
  dispatch({
    type: types.SET_IN_AMOUNT,
    payload: amount
  });
}

export const setTrade = (bestTrade) => dispatch =>{
  dispatch({
      type: types.SET_TRADE,
      payload: bestTrade
  })
}

export const setMinReceive = (amount) => dispatch =>{
  dispatch({
      type: types.SET_MIN_RECEIVED,
      payload: amount
  })
}

const setParsedAmount = (amount) => dispatch =>{
  dispatch({
      type: types.SET_PARSED_AMOUNT,
      payload: amount
  })
}

const getTradeFeePrice = (trade) => {
  return computeTradePriceBreakdown(trade)
}

const getSwapInfo = (amountIn, isExactIn=true, isSwap=false, check=true) => async(dispatch) => {

  try{
    if(+amountIn > 0){
      const { tokenIn, tokenOut, independentField, trade, slippageTolerance } = store.getState().swap;
      const inputCurrency = getCurrency(tokenIn.address || tokenIn.symbol)
      const outputCurrency = getCurrency(tokenOut.address || tokenOut.symbol)
      let parsedAmount = getParsedAmount(amountIn.toString(), isExactIn ? inputCurrency : outputCurrency)
      dispatch(setParsedAmount(parsedAmount))
      const { bestV2Trade, smartTrade } = await getBestV2Trade(parsedAmount, isExactIn ? outputCurrency : inputCurrency, isExactIn)
      const smartRouterBetter = isSmartRouterBetter({ trade: smartTrade, v2Trade: bestV2Trade })
      const tradeInfo = getTradeInfo({
        trade: smartTrade,
        v2Trade: bestV2Trade,
        useSmartRouter: smartRouterBetter,
        allowedSlippage: +slippageTolerance * 100,
        chainId: 56,
      })
      dispatch(setAmountIn(independentField === 'INPUT' ? amountIn : tradeInfo?.inputAmount?.toSignificant(6)))
      dispatch(setAmountOut(independentField === 'OUTPUT' ? amountIn : tradeInfo?.outputAmount?.toSignificant(6)))
      if(isSwap){
        dispatch({
          type: types.SET_UPDATED_TRADE,
          payload: tradeInfo
        })
        if(isExactIn){
          let minAmount = +trade?.outputAmount?.toSignificant(6) * ((100 - +slippageTolerance) / 100)
          if( minAmount > +tradeInfo?.outputAmount?.toSignificant(6)){
            dispatch(errorActions.setConfirmModal(true))
          }else{
            dispatch(setTrade(tradeInfo))
            dispatch(getSwapTransaction())
          }
        }else{
          let minAmount = +trade?.inputAmount?.toSignificant(6) * ((100 + +slippageTolerance) / 100)
          if(minAmount < +tradeInfo?.inputAmount?.toSignificant(6)){
            dispatch(errorActions.setConfirmModal(true))
          }else{
            dispatch(setTrade(tradeInfo))
            dispatch(getSwapTransaction())
          }
        }        
      }else{
        dispatch(setTrade(tradeInfo))
        dispatch(setMinReceive(tradeInfo?.slippageAdjustedAmounts?.OUTPUT))
      } 
    }else{
      dispatch(setTrade(null))
      dispatch(setMinReceive(0))
    }
    dispatch(checkSwapStatus(check ? amountIn : '0'))
  }catch(e){
    console.log(e);
    dispatch(errorActions.checkErrors(e))
  }
}

export function getTradeInfo({
  trade,
  v2Trade,
  useSmartRouter = true,
  allowedSlippage = 0,
}){
    if (!trade && !v2Trade) {
      return null
    }
    const smartRouterAvailable = useSmartRouter && !!trade
    const fallbackV2 = !smartRouterAvailable || trade?.route.routeType === RouteType.V2

    if (fallbackV2) {
      if (v2Trade) {
        const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(v2Trade)
        return {
          tradeType: v2Trade.tradeType,
          fallbackV2,
          route: v2Trade.route,
          inputAmount: v2Trade.inputAmount,
          outputAmount: v2Trade.outputAmount,
          slippageAdjustedAmounts: computeSlippageAdjustedAmounts(v2Trade, allowedSlippage),
          executionPrice: v2Trade.executionPrice,
          routerAddress: V2_ROUTER_ADDRESS,
          priceImpactWithoutFee,
          realizedLPFee,
          ...v2Trade
        }
      }
      return null
    }

    const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdownSmartRouter(trade)
    return {
      tradeType: trade.tradeType,
      fallbackV2,
      route: trade.route,
      inputAmount: trade.inputAmount,
      outputAmount: trade.outputAmount,
      slippageAdjustedAmounts: computeSlippageAdjustedAmountsSmart(trade, allowedSlippage),
      executionPrice: SmartRouterTrade.executionPrice(trade),
      routerAddress: SMART_ROUTER_ADDRESS,
      priceImpactWithoutFee,
      realizedLPFee,
      ...trade
    }
}



const checkSwapStatus = (amount) => dispatch => {
  const { tokenIn, slippageTolerance, trade, independentField } = store.getState().swap
  const { activeWallet, allowance } = store.getState().wallet
  const { v2Allowance, smartAllowance } = allowance
  const currentAllowance = trade?.routerAddress === V2_ROUTER_ADDRESS ? v2Allowance : smartAllowance
  const priceImpactWithoutFee = trade?.priceImpactWithoutFee
  const balance = tokenIn?.balance
  let feeProcent = activeWallet?.code === tokenIn?.symbol ? 0.01 : 0
  amount = independentField === 'INPUT' ? amount : trade?.inputAmount?.toSignificant(6)
  if(+amount > 0) {
    if(+amount > +balance){
      dispatch(setSwapStatus('insufficientBalance'))
    } else if(+amount <= BigNumber(+balance).minus(feeProcent).toNumber() && +activeWallet?.balance > 0){
        if(BigNumber(currentAllowance).div(BigNumber(Math.pow(10,+tokenIn.decimals))).toNumber() > +amount || tokenIn.symbol === 'BNB'){
          if(parseFloat(priceImpactWithoutFee?.toFixed(2)||0) < +slippageTolerance){
            dispatch(setSwapStatus('swap'))
          }else{
            dispatch(setSwapStatus('swapAnyway'))
          }
        } else {
          dispatch(setSwapStatus('approve'))
        }
    } else {
      dispatch(setSwapStatus('feeError'))
    }
  } else {
    dispatch(setSwapStatus('enterAmount'))
  }
}

const getApproveTransaction  = () => dispatch => {
  const wallet = walletActions.getWalletConstructor()
  if(wallet){
    const { tokenIn, trade } = store.getState().swap;
    const contractData = {
      address: trade?.routerAddress,
      name: trade?.routerAddress === V2_ROUTER_ADDRESS ? "PancakeSwap: V2 Router" : "PancakeSwap: StableSwap",
      url: "https://bscscan.com/address/" + trade?.routerAddress
    }
    const transaction = wallet.generateApproveTransaction(tokenIn,contractData)
    wallet.prepareTransfer(transaction).then(res => {
      if(res.ok){
        return dispatch ({
          type: types.SET_PREPARE_TRANSFER_RESPONSE,
          payload: res.data
        })
      }else{
        dispatch(errorActions.checkErrors(res))
      }
    }).catch(err => {
      dispatch(errorActions.checkErrors(err.response?.data?.error))
    })
  }
}

const checkTradeUpdate = () => {
  const { amount, isExactIn  } = store.getState().swap
  store.dispatch(getSwapInfo(amount,isExactIn,true))
}

const getSwapTransaction  = () => async(dispatch) => {
  const wallet = walletActions.getWalletConstructor()
  dispatch(setSwapDisable(true))
  if(wallet){
    const { deadlineMin } = store.getState().swap
    const deadline = await wallet.loadBlockNumber(deadlineMin)
    const {trade,slippageTolerance,isExactIn,tokenIn,tokenOut,amountOut,amount,amountIn} = store.getState().swap;
    let transaction = null
    if(tokenIn.symbol === 'BNB' && tokenOut.symbol === 'WBNB'){
      transaction = wallet.generateDepositTransaction(tokenIn,amount,tokenOut)
    }else if(tokenIn.symbol === 'WBNB' && tokenOut.symbol === 'BNB'){
      transaction = wallet.generateWithdrawTransaction(tokenIn,amount,tokenOut)
    }else{
      transaction = wallet.generateSwapTransaction(tokenIn,amountIn,tokenOut,amountOut,trade,deadline,slippageTolerance,isExactIn)
    }
    wallet.prepareTransfer(transaction).then((response) => {
        if(response?.ok){
          dispatch ({
            type: types.SET_PREPARE_TRANSFER_RESPONSE,
            payload: response.data
          })
        }else{
          dispatch(errorActions.checkErrors(response))
        }
    }).catch(err => {
      dispatch(errorActions.checkErrors(err))
    })
  }
  setTimeout(() => {
    dispatch(setSwapDisable(false))
  }, 5000);
}


export const swapActions = {
  setSwapDisable,
  setSwapStatus,
  setSlippageTolerance,
  setIndependentField,
  setAmount,
  setTokenIn,
  setTokenOut,
  setAmountOut,
  setSelectedToken,
  getSwapInfo,
  setParsedAmount,
  getTradeFeePrice,
  getApproveTransaction,
  getSwapTransaction,
  checkTradeUpdate,
  setAmountIn,
  setTrade,
  checkSwapStatus
};