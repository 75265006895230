import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Content, Header, Tabbar, Input, Card, Button, Modal, Alert, Checkbox, Icon, Loader } from '@citadeldao/apps-ui-kit/dist/main';
import { Config } from '../config/config';
import { formatAddress } from '../helpers/addressFormatter';
import { walletActions, swapActions } from '../../store/actions';
import { store } from '../../store/store';
import ROUTES from '../../routes';
import '../styles/panels/manageTokenPanel.css';

const ManageTokensPanel = () => {
    const config = new Config()
    const navigate = useNavigate()
    const [value, setValue] = useState("");
    const [token, setToken] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoader] = useState(false)
    const { bottomInset } = useSelector(state => state.panels)
    const { selectedToken, tokenIn, tokenOut  } = useSelector(state => state.swap)
    const { coingeckoTokens, extendedTokens, coinMarketCapTokens, customTokens, tokens, customToken } = useSelector(state => state.wallet)
    const back = () => navigate('/main/select_token?' + window.location.search.slice(1))

    const searchTokens = (value) => {
        setValue(value)
        if(value?.length === 42 && value.includes('0x')) {
            let token = (tokens?.find(token => token?.address?.toLowerCase() === value?.toLowerCase()) || coingeckoTokens?.find(token => token?.address?.toLowerCase() === value?.toLowerCase()) || extendedTokens?.find(token => token?.address?.toLowerCase() === value?.toLowerCase()) || coinMarketCapTokens?.find(token => token?.address?.toLowerCase() === value?.toLowerCase()))
            tokens?.forEach((item) => {
                if(item?.address === token?.address) {
                    setToken(item)
                } else {
                    setToken(token)
                }
                setError(false)
            })
            if(!token && value) {
                setLoader(true)
                walletActions.loadCustomToken(value)
            }
        }else if(value.length > 0){
            setError('Enter valid contract address')
            walletActions.setCustomToken(null)
            setToken(null)
            setLoader(false)
        }
    }

    const importToken = () => {
        if(customToken) {
            walletActions.checkTokens(customToken)
            customToken.label = customToken?.symbol
            customToken.value = customToken?.symbol
            if(selectedToken === "INPUT") {
                store.dispatch(swapActions.setTokenIn(customToken));
            } else {
                store.dispatch(swapActions.setTokenOut(customToken));
            }
        } else {
            if(selectedToken === "INPUT") {
                store.dispatch(swapActions.setTokenIn(token));
            } else {
                store.dispatch(swapActions.setTokenOut(token));
            }
        }
        walletActions.checkTokens(token)
        navigate(ROUTES.SWAP)
    }

    const setSwapToken = (token) => {
        let checkToken = selectedToken !== "INPUT" ? tokenIn : tokenOut
        if(checkToken.address !== token.address){
            if(selectedToken === "INPUT") {
                store.dispatch(swapActions.setTokenIn(token));
            } else {
                store.dispatch(swapActions.setTokenOut(token));
            }
            navigate(ROUTES.SWAP)
        }
    }

    const getCardClassName = (token) => {
        let checkToken = selectedToken !== "INPUT" ? tokenIn : tokenOut
        if(checkToken.address === token.address){
            return 'token__card-not-allowed'
        }
        return ''
    }

    useEffect(() => {
        if(value?.length === 0){
            walletActions.setCustomToken(null)
            setToken(null)
            setError(false)
            setLoader(false)
        }
    }, [value])

    useEffect(() => {
        if(customToken?.address){
            setError(false)
        }else if(typeof customToken === 'string'){
            setError(customToken)    
        }
        setLoader(false)
    }, [customToken])

    return (
        <div className='panel'>
            <Content>
                <Header border title="Manage tokens" style={{margin: '8px 0 16px 0'}} onClick={() => back()} back={true}/>
                <Input
                    label='Add token contract address'
                    placeholder='0x000'
                    value={value}
                    type='text'
                    clearable
                    onChange={searchTokens}
                />
                { (token || customToken?.address) ?
                    <Card className="token__card-block">
                        <div className='token__card'>
                            <img src={token?.logoURI || customToken?.logoURI} alt='token-img'></img>
                            <p>{token?.name || customToken?.name}</p>
                        </div>
                        {tokens?.some(element => (
                            element?.address === token?.address && token?.address )) ? 
                                <Button 
                                    type='small' 
                                    style={{cursor: "default"}}
                                    disabled
                                >
                                    IMPORTED
                                </Button>
                            :  
                                <Button 
                                type='small' 
                                hoverTextColor='white' 
                                hoverBgColor='#5639E0'
                                onClick={() => setShowModal(true)}
                            >
                                IMPORT
                            </Button>
                        }
                    </Card>
                    : loading && <Loader/> 
                }
                {error && <p className='no-data'>{error}</p>}
                { customTokens?.length > 0 &&
                    <div className='custom-token-block'>
                        <div className='custom-token-info'>
                            <p className='custom-token__count'>{customTokens?.length} tokens</p>
                            <p className='custom-token__error' 
                                onClick={() => { 
                                    walletActions.removeAllCustomTokens()
                                }}
                            >Clear all</p>
                        </div>
                        {customTokens?.map((token, i) => (
                            <Card className={`token__card-block ${getCardClassName(token)}`} key={i}>
                                <div className='token__card' onClick={() => setSwapToken(token)}>
                                    <img src={token?.logoURI} alt='token-img'></img>
                                    <div className='token__info'>
                                        <p className='token__name'>{token?.name} / <span>{token?.symbol}</span></p>
                                        <p className='token__address'>{formatAddress(token?.address)}</p>
                                    </div>
                                </div>
                                <div className='token__action'>
                                    <a className='token__link' href={`https://bscscan.com/address/${token?.address}`} rel="noreferrer" target={'_blank'}>
                                        <Icon name = "arrow-from-square-up-right" color="#00B2FE"/>
                                    </a>
                                    <div className='token__link' onClick={() => walletActions.removeCustomToken(token)}>
                                        <Icon name = "trash" color="#CC3030"/>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                }
                <Modal
                    title={<Alert text='Trade at your own risk!' iconColor="#ED4242" boldText/>}
                    show={showModal}
                    onClose={() => setShowModal(false)}
                >
                    <Card className="token__card-block">
                        <div className='token__card'>
                            <img src={token?.logoURI || customToken?.logoURI} alt='token-img'></img>
                            <div className='token__info'>
                                <p className='token__name'>{token?.name || customToken?.name} / <span>{token?.symbol || customToken?.symbol}</span></p>
                                <p className='token__address'>{formatAddress(token?.address || customToken?.address)}</p>
                            </div>
                        </div>
                        <a className='token__link' href={`https://bscscan.com/address/${token?.address || customToken?.address}`} rel="noreferrer" target={'_blank'}>
                            <Icon name = "arrow-from-square-up-right" color="#00B2FE"/>
                        </a>
                    </Card>
                    <p className='token__desc'>Anyone can create a token, including creating fake versions of existing tokens that claim to represent projects. If you purchase this token, you may not be able to sell it back</p>
                    <div className='div--center'>
                        <Checkbox
                            value={isSelected}
                            onChange={(val) => setIsSelected(val)}
                        >
                            I understand
                        </Checkbox>
                        <Button
                            disabled={!isSelected && true}
                            bgColor={isSelected && '#7C63F5'}
                            className='btn'
                            onClick={() => importToken()}
                        >
                            IMPORT
                        </Button>
                    </div>
                </Modal>
            </Content>
            <Tabbar config={config}  bottomInset={bottomInset}/>
        </div>
    )
}

export default ManageTokensPanel