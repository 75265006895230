import { bsc } from 'wagmi/chains'
import { configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const CHAINS = [bsc]
export const { provider, chains } = configureChains(CHAINS, [
    jsonRpcProvider({
      rpc: () => {
        return { http: process.env.REACT_APP_PUBLIC_NODE_PRODUCTION }
      },
    }),
  ])
  