import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Content, Header, Tabbar, Input } from '@citadeldao/apps-ui-kit/dist/main';
import AddressBlock from '@citadeldao/apps-ui-kit/dist/components/uiKit/AddressBlock'
import { Config } from '../config/config';
import { walletActions, panelActions } from '../../store/actions';
import { prettyNumber } from '../helpers/numberFormatter';

const SelectAddressPanel = () => {
  const config = new Config() 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { bottomInset } = useSelector(state => state.panels)
  const { wallets, activeWallet, usdPrice } = useSelector((state) => state.wallet)
  const [walletList, setWalletList] = useState(wallets)
  const previousPanel = useSelector(state => state.panels.previousPanel)

  const back = () => navigate(previousPanel)
  const searchWallet = (wallet) => {
    let arr = wallets.filter(
      (item) =>
        item.code.substr(0, wallet.length).toLowerCase() ===
          wallet.toLowerCase() ||
        item.name.substr(0, wallet.length).toLowerCase() ===
          wallet.toLowerCase() ||
        item.address.substr(0, wallet.length).toLowerCase() ===
          wallet.toLowerCase()
    );
    setWalletList(arr);
    if (wallet.length < 1) setWalletList(wallets);
  };
  
  const setActiveWallet = (wallet) => {
    dispatch(walletActions.setActiveWallet(wallet))
    back();
  }

  useEffect(()=>{
    dispatch(panelActions.setCurrentPanel(location.pathname))
    // eslint-disable-next-line
  },[])

  return (
    <div className='panel'>
      <Content>
        <Header border title="Select an address" style={{margin: '8px 0 16px 0'}} onClick={() => back()} back={true}/>
        <Input type="search" style={{marginBottom: '10px'}} onChange={searchWallet} placeholder='Start typing..'/>
        { walletList?.map((elem,i) =>(
          <AddressBlock onClick={() => setActiveWallet(elem)} active={activeWallet?.address === elem?.address} style={{marginBottom: '10px'}} data={{...elem, balance: prettyNumber(elem?.balance)}} key={i} usdPrice={usdPrice > 0 ? prettyNumber(elem.balance * usdPrice,2) + ' ' : ''}/>  
        ))}
      </Content>
      <Tabbar config={config}  bottomInset={bottomInset}/>
    </div>
  )
}

export default SelectAddressPanel