import React, { useEffect } from "react";
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import MainView from './MainView.js';
import { store } from './store/store.js';
import { walletActions, swapActions } from './store/actions/index.js'
import { SocketManager } from './networking/socket';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    SocketManager.connect();
    dispatch(walletActions.loadWalletWithBalances());
    dispatch(swapActions.getSwapInfo('1',true,false,false))
    dispatch(walletActions.loadCoingeckoTokens())
    dispatch(walletActions.loadCoinMarketCapTokens())
    dispatch(walletActions.loadExtendedTokens())   
    walletActions.checkTokens()
    // eslint-disable-next-line
  },[])

  return (
    <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path="/*" element={<MainView />} />
        </Routes>
      </HashRouter>
    </Provider>
  );
}

export default App;