import { types } from '../actions/types'
import tokenList from '../../networking/constants/tokenLists/pancake-default.tokenlist.json'

const tokens = [
    { chainId: 56, symbol: 'BNB', name: "Binance Smart Chain", network: 'bsc', logoURI: "https://bscscan.com/token/images/binance_32.png"}, 
    ...tokenList['tokens']
]
tokens?.forEach((token => token.status = 'active'))

const initialState = {
    wallets: [],
    networks: null,
    stakeNodes: null,
    transactionResponse: null,
    loader: true,
    activeWallet: null,
    showSplash: true,
    tokens: tokens,
    allowance: 0,
    usdPrice: 0,
    coingeckoTokens: null,
    extendedTokens: null,
    coinMarketCapTokens: null,
    customTokens: [],
    customToken: null
}

export default function WalletReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_WALLETS:
            return {
                ...state,
                wallets: action.payload
            }
        case types.SET_USD_PRICE:
            return {
                ...state,
                usdPrice: action.payload
            }
        case types.SET_ALLOWANCE:
            return {
                ...state,
                allowance: action.payload
            }
        case types.SET_TOKENS:
            return {
                ...state,
                tokens: action.payload
            }
        case types.SET_SPLASH_MODE:
            return {
                ...state,
                showSplash: action.payload,
            };
        case types.SET_ACTIVE_WALLET:
            return {
                ...state,
                activeWallet: action.payload,
            };
        case types.SET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        case types.SET_PREPARE_TRANSFER_RESPONSE:
            return {
                ...state,
                transactionResponse: action.payload,
            };
        case types.SET_NETWORKS:
            return {
                ...state,
                networks: action.payload,
            };
        case types.SET_STAKE_NODES:
            return {
                ...state,
                stakeNodes: action.payload,
            };
        case types.SET_COINGECKO_TOKENS: 
            return {
                ...state,
                coingeckoTokens: action.payload
            }
        case types.SET_EXTENDED_TOKENS:
            return {
                ...state,
                extendedTokens: action.payload
            }
        case types.SET_COINMARKETCAP_TOKENS: 
            return {
                ...state,
                coinMarketCapTokens: action.payload
            }
        case types.SET_CUSTOM_TOKENS:
            return {
                ...state,
                customTokens: action.payload
            }
        case types.SET_CUSTOM_TOKEN: 
            return {
                ...state,
                customToken: action.payload
            }
        default:
            return state;
    }
}