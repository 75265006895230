import React, {useState,useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Content, IconButton, Tabbar, EditAmount,  Input, Select, InputCard, InfoCardBlock, InfoCardItem, FormGroup, FormGroupBalance } from '@citadeldao/apps-ui-kit/dist/main';
import SwapButton from '../uikit/SwapButton';
import ConfirmModal from '../uikit/ConfirmModal';

import ROUTES from '../../routes';
import BigNumber from "bignumber.js";
import { Config } from '../config/config';
import { panelActions, swapActions } from '../../store/actions';
import { ONE_BIPS } from '../../networking/constants/constants';
import { prettyNumber } from '../helpers/numberFormatter';

import '../styles/panels/swap.css';

const SwapPanel = () => {
    const config = new Config()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    
    const { bottomInset } = useSelector(state => state.panels)
    const { independentField, minReceived, slippageTolerance, isExactIn, parsedAmount, amount, trade, tokenIn, tokenOut } = useSelector(state => state.swap)
    const [slippage, setSlippage] = useState(slippageTolerance)
    const [isExact, setExactIn] = useState(isExactIn);
    const formattedPrice = trade?.executionPrice?.toSignificant(6)

    useEffect(()=>{
        dispatch(panelActions.setPreviousPanel(location.pathname))
         // eslint-disable-next-line
    },[])

    const dependentField = independentField === "INPUT" ? "OUTPUT" : "INPUT";
    const parsedAmounts =  {
        'INPUT': independentField === 'INPUT' ? parsedAmount : trade?.inputAmount,
        'OUTPUT': independentField === 'OUTPUT' ? parsedAmount : trade?.outputAmount,
    }

    let isBNB = false
    let routes = []

    if(tokenIn?.symbol === 'BNB' && tokenOut?.symbol === 'WBNB'){
        routes.push({logoURI: tokenIn?.logoURI, name: tokenIn?.symbol})
        routes.push({logoURI: tokenOut?.logoURI, name: tokenOut?.symbol})
        isBNB = true
    }else if(tokenIn?.symbol === 'WBNB' && tokenOut?.symbol === 'BNB'){
        routes.push({logoURI: tokenIn?.logoURI, name: tokenIn?.symbol})
        routes.push({logoURI: tokenOut?.logoURI, name: tokenOut?.symbol})
        isBNB = true
    }else if(trade?.route?.path){
        routes = trade?.route?.path?.map(item => {
            return {
                logoURI: item.tokenInfo?.logoURI,
                name: item.symbol,
            };
        });
        isBNB = false;
    }
    
    let formattedAmounts = {};
    if (isBNB) {
        formattedAmounts = {
            [independentField]: amount,
            [dependentField]: amount,
        };
    } else {
        formattedAmounts = {
            [independentField]: amount,
            [dependentField]: +amount !== 0 ? parsedAmounts[dependentField]?.toSignificant(6) || 0 : '0',
        };
    }
    
    const reverseTokens = () => {
        dispatch(swapActions.setIndependentField(dependentField));
        dispatch(swapActions.setTokenIn(tokenOut));
        dispatch(swapActions.setTokenOut(tokenIn));
        dispatch(swapActions.setAmount(formattedAmounts[independentField], !isExact));
        dispatch(swapActions.getSwapInfo(formattedAmounts[independentField], !isExact));
        setExactIn(!isExact);
    };

    const setSelectedOption = (name) => {
        dispatch(swapActions.setIndependentField('INPUT'));
        setExactIn(true);
        dispatch(swapActions.setAmount(formattedAmounts['INPUT'], true));
        dispatch(swapActions.setSelectedToken(name));
        navigate(ROUTES.SELECT_TOKEN);
    };

    const setMaxValue = (val) => {
        setExactIn(val === 'INPUT' ? true : false);
        dispatch(swapActions.setIndependentField(val));
        formattedAmounts[val] = val === 'INPUT' ? tokenIn.balance : tokenOut.balance;
        if (formattedAmounts[val] === 0 || formattedAmounts[val] === '~0') {
            dispatch(swapActions.setSwapStatus('insufficientBalance'));
            formattedAmounts[val] = 0;
        }
        let currentToken = val === 'INPUT' ? tokenIn : tokenOut;
        if (currentToken.symbol === 'BNB' && formattedAmounts[val] > 0.01) {
            formattedAmounts[val] = BigNumber(formattedAmounts[val]).minus(0.01).toNumber();
        }
        dispatch(swapActions.setAmount(formattedAmounts[val], val === 'INPUT' ? true : false));
        dispatch(swapActions.getSwapInfo(formattedAmounts[val], val === 'INPUT' ? true : false));
    };
    useEffect(() => {

        let interval = null;
        if (!trade && +amount !== 0) {
            interval = setInterval(() => {
                dispatch(swapActions.getSwapInfo(amount, isExact));
            }, 1500);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line 
    }, [amount, tokenIn, tokenOut, trade]);

    const checkAmount = (val, name) => {
        // eslint-disable-next-line 
        val = val.replace(/[^0-9\.]/g, '');
        if (val.split('.').length - 1 !== 1 && val[val.length - 1] === '.') return;
        if (val.length === 2 && val[1] !== '.' && val[1] === '0' && val[0] === '0') {
            dispatch(swapActions.setAmount(val[0], name === 'INPUT' ? true : false));
        } else if (val[0] === '0' && val[1] !== '.') {
            dispatch(swapActions.setAmount(BigNumber(val).toFixed(), name === 'INPUT' ? true : false));
        } else {
            dispatch(swapActions.setAmount(val, name === 'INPUT' ? true : false));
        }
        dispatch(swapActions.setIndependentField(name));
        setExactIn(name === 'INPUT' ? true : false);
        dispatch(swapActions.getSwapInfo(val, name === 'INPUT' ? true : false));
    };

    const setSlippageTolerance = (val) => {
        setSlippage(val);
        dispatch(swapActions.setSlippageTolerance(val));
    };
 
    return (
        <div className="panel swap-panel">
            <Content>
                <div className="swap-inputs">
                    <FormGroup>
                        <FormGroupBalance 
                            placement="end" 
                            balance={prettyNumber(tokenIn?.balance)} 
                            text="Balance"
                            currency={tokenIn?.symbol}
                            loading={!(prettyNumber(tokenIn?.balance) || 0)}
                        />
                         <InputCard> 
                            <Select
                                value={tokenIn?.symbol}
                                options={[{...tokenIn, icon: tokenIn.logoURI, value: tokenIn.symbol, label: tokenIn.symbol}]}
                                label={'From token'}
                                onClick={() => setSelectedOption('INPUT')}
                            />
                             <Input
                                value={formattedAmounts['INPUT']}
                                label={'Amount'}
                                inverted
                                type="amount"
                                currency={tokenIn?.symbol}
                                name={'INPUT'}
                                onChange={checkAmount}
                                action={{ text: 'MAX', onClick: () => setMaxValue('INPUT') }}
                            />
                        </InputCard>
                    </FormGroup>
                    <IconButton
                        onClick={reverseTokens}
                        type="hexagon"
                        icon="arrows-towards"
                        className="swap-center-btn"
                        bgColor="#C6D1FF"
                        iconColor="#173296"
                        borderColor="#869FFF"/>
                    <FormGroup>
                    <InputCard>
                            <Select
                                value={tokenOut?.symbol}
                                options={[{...tokenOut, icon: tokenOut.logoURI, value: tokenOut.symbol, label: tokenOut.symbol}]}
                                label={'To token'}
                                onClick={() => setSelectedOption('OUTPUT')}
                            />
                            <Input
                                value={formattedAmounts['OUTPUT']}
                                label={'Amount'}
                                name={'OUTPUT'}
                                inverted
                                type="amount"
                                currency={tokenOut?.symbol}
                                onChange={checkAmount}
                            />
                        </InputCard>
                        <FormGroupBalance 
                            placement="end" 
                            balance={prettyNumber(tokenOut?.balance)} 
                            text="Balance"
                            currency={tokenOut.symbol}
                            loading={!(prettyNumber(tokenOut?.balance) || 0)}
                        />
                    </FormGroup>
                </div>
                <InfoCardBlock style={{marginTop: '10px'}}>
                    <InfoCardItem text={'Price'} symbol={tokenOut?.symbol} symbol2={tokenIn?.symbol}><span className='purple-text'>{formattedPrice || '-'}</span></InfoCardItem>
                    <InfoCardItem text={'Price impact'} symbol={'%'}><span className='green-text'>{trade?.priceImpactWithoutFee ? (trade?.priceImpactWithoutFee.lessThan(ONE_BIPS) ? '<0.01' : `${trade?.priceImpactWithoutFee.toFixed(2)}`) : '-'}</span></InfoCardItem>
                    <InfoCardItem text={'Minimum received'} symbol={tokenOut?.symbol}><span className='purple-text'>{minReceived !== 0 ? minReceived?.toSignificant(4) : minReceived}</span></InfoCardItem>
                    <InfoCardItem text={'Liquidity Provider Fee'} symbol={tokenIn?.symbol}><span className='pink-text'>{trade?.realizedLPFee?.toSignificant(4) || 0}</span></InfoCardItem>
                    {
                        routes?.length ?
                        <InfoCardItem text={'Route'} routes={routes}/>:
                        <InfoCardItem text={'Route'} symbol={'-'}/>
                    }
                </InfoCardBlock>
                <EditAmount 
                    data={{ code: '%' }} 
                    style={{ marginTop: '20px' }} 
                    text={'Slippage tolerance'} 
                    value={slippage} 
                    defaultValue={1}
                    minValue={0.01}
                    saveValue={() => {}} 
                    maxValue={49} 
                    setValue={setSlippageTolerance}
                />
                <SwapButton isBNB={isBNB}/>
            </Content>
            <ConfirmModal/>
            <Tabbar config={config} bottomInset={bottomInset}/>
        </div>
    );
};

export default SwapPanel;